import React from "react";
// import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";
// import DotsBg from "../../assets/images/careers/dots.png";
// import BackgroundImage from "gatsby-background-image";

const Description = ({ descriptionContent }) => {
  const { duties, requirements, plus, benefits, requirementsList } =
    descriptionContent;
  const { t } = useTranslation();

  return (
    <section className="m-description">
      <div className="_wr">
        <div className="_w">
          <div className="m-description__content _12">
            <div className="m-description__titleLine">
              <h2 className="m-description__title">Job information</h2>
              <span className="a-line -red"></span>
            </div>
          </div>
        </div>
        <div className="_w m-description__text">
          {duties && (
            <div className="m-description__duties _m6">
              <h3>{t("careers.openPositions.duties")}</h3>
              <ul>
                {duties?.map((listItem, key) => (
                  <li key={key}>{listItem}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="m-description__requirements _12 _m6">
            <h3>{t("careers.openPositions.requirements")}</h3>
            <ul>
              {requirements.map((listItem, key) => (
                <li key={key}>{listItem}</li>
              ))}
            </ul>
            <div className="m-description__requirements ofs_1 _8">
              <ul className="m-description__requirments--list">
                {requirementsList?.map((listItem, key) => (
                  <li key={key}>{listItem}</li>
                ))}
              </ul>
            </div>
          </div>
          {plus.length !== 0 ? (
          <div className="m-description__duties--plus _12 _m6">
            <h3>{t("careers.openPositions.plus")}</h3>
            <ul>
              {plus.map((listItem, key) => (
                <li key={key}>{listItem}</li>
              ))}
            </ul>
          </div>
          ) : ''}
        </div>

        <h3>{t("careers.openPositions.benefits")}</h3>
        <div className="m-description__text--benefits">
          <ul className="_w">
            {benefits.map((listItem, key) => (
              <li key={key} className="_12 _m6">
                {listItem}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Description;

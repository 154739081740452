import React from "react";
import HeaderBcg from "../../assets/images/headers/career-single.png";

const HeaderCareer = ({
  headerContent: { title, location, numberOfPositions, imageUrl },
}) => {
  return (
    <header className="m-headerCareer">
      <div
        className="_wr m-headerCareer__wrapper"
        // style={{ backgroundImage: `url("${imageUrl}")` }}
      >
        <div className="m-headerCareer__content">
          <h1 className="m-headerCareer__title">{title}</h1>
          <div className="m-headerCareer__info">
            <p>
              Location: <span>{location}</span>
            </p>
            <p>
              Open position: <span>{numberOfPositions}</span>
            </p>
          </div>
        </div>
        <div
          className="m-headerMarkets__bkg -careers _m5 _xl6"
          style={{ backgroundImage: `url("${HeaderBcg}")` }}
        ></div>
      </div>
    </header>
  );
};

export default HeaderCareer;

import React from "react";
import { useTranslation } from "react-i18next";

const CtaCarrers = () => {
  const { t } = useTranslation();
  const title = `${t("careers.apply.title")}`;
  return (
    <section className="m-cta">
      <h2> {title} </h2>
      <span className="a-line -red"></span>
      <p>{t("careers.sendCV")}</p>
      <a href="mailto:karijera@ewasoft.rs">karijera@ewasoft.rs</a>
    </section>
  );
};

export default CtaCarrers;
